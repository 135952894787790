import JSBI from 'jsbi'

export const FACTORY_ADDRESS = '0xF1A8d313a71eaC0D433454B5407265425bcC7C63'

export const INIT_CODE_HASH = '0x073596c693d0c154e14fd8a67b67ebf2a6a6042361e65135693a401a0cbfe5f0'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
